export default {
    gallery: {
        "addGalleryPhoto": "Добавить фото в галерею",
        "photo_count": "Всего {count} фото",
        "main_photo": "Главное фото",
        "edit": "Редактировать файл",
        "delete": "Удалить файл",
        "description": "Описание фото",
        "setMainPhoto": "Установить как главное фото",
        "addPhoto": "Добавить фото",
        "save": "Сохранить",
        "cancel": "Отмена",
        "close": "Закрыть",
        "fileUpdated": "Файл успешно обновлен",
        "deleteMessage": "Вы действительно хотите удалить файл?",
        "delete2": "Удалить",
        "fileDeleted": "Файл удален",
        "deletedError": "Ошибка удаления",
        "fileLoadSuccess": "Файлы успешно загружены",
        "fileLoadError": "Произошла ошибка при загрузке файлов. Пожалуйста, попробуйте снова."
    }
}